<template>
  <div>
    <div class="d-flex justify-content-end mb-4">

      <div class="card-toolbar">

        <a v-if="show_create" href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="create_company_period_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('COMPANY_PERIOD.NEW') }} </a
        >
      </div>
    </div>

    <b-form-group :label="$t('MEMBER.LANG')" class="col-4">
      <b-form-select
        v-model="lang"
        :options="[
          { value: 'sv', text: $t('LANGUAGE.SWEDISH') },
          { value: 'ar', text: $t('LANGUAGE.ARABIC') },
          { value: 'bs', text: $t('LANGUAGE.BOSNIAN') }
        ]"
      >

      </b-form-select>

    </b-form-group>
    
    <b-row class="mb-4">
      <b-col lg="6" sm="12">
        <b-form-input
          id="filter-input"
          v-model="filters.text"
          type="search"
          style="max-width: 621px"
          :placeholder="$t('COMMON.SEARCH')"
        ></b-form-input>
      </b-col>


      <b-col lg="6" sm="12">
        <div class="d-sm-flex justify-content-end text-right float-right align-bottom w-100">
          <b-form-select class="pagination-select" v-model="per_page" :options="per_page_options"></b-form-select>
          <b-pagination
            class="mb-0 mr-2"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="company_period-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="company_period-table"
      ref="company-period-table"
      class="table-striped mh-100"
      :fields="headers"
      :items="items"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      sticky-header
      responsive
      striped
      hover
      selectable
      @row-clicked="handle_row_clicked"
      :tbody-tr-class="table_row_class"
    >
      <!-- this is a built in exand slot which will insert a new row inside the table when clicking on a row -->
      <template #row-details="row">
        <b-card v-if="row.item && row.item.id">
          <CompanyPeriodInfoBox
            v-bind:value="row.item"
            @calculate_advance="calculate_advance"
            @calculate_settled="calculate_settled"
            @company_period_updated="company_period_updated"
          />
        </b-card>
      </template>
      <template #cell(expand)="data">
        <span class="svg-icon svg-icon-sm text-primary">
          <inline-svg
            :src="data.detailsShowing ? '/assets/svg/chevron-up.svg' : '/assets/svg/chevron-down.svg'"
          ></inline-svg>
        </span>
      </template>
      <template #cell(actions)="row">
        <div class='justify-content-end d-flex'>

          <a v-if="row.item.is_missing" href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="create_company_period_for_company_clicked(row.item.company.company_id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/circle-plus-solid.svg"></inline-svg>
            </span>
          </a>

          <a href="#" class="btn btn-icon btn-light btn-sm ml-3" @click.prevent="delete_company_period_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm ml-3"
            @click.prevent="download_company_period_report_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
              <!--end::Svg Icon-->
            </span>
          </a>

        </div>
      </template>


    </b-table>

    <div v-if="items.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import CompanyPeriodInfoBox from '@/view/pages/ml/faith/company_period/CompanyPeriodInfoBox.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  name: "company_periods-table",
  mixins: [ toasts ],
  props: ["items","show_create"],
  emits: ["create_company_period","update_company_period","delete_company_period","create_company_period_for_company","select_company","select_company_period"],
  components: {
    CompanyPeriodInfoBox,
  },
  watch: {
    'filters.text'(){
      this.$emit('text_changed', this.filters.text)
    }
  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    company_period_updated(company_period) {

      this.$emit('update_company_period', company_period);

    },

    /// Expands the row of the table with the given company_period id
    /// It really works!
    expand_row(id) {
      let item = this.items.find(item => item.id === id);

      item._showDetails = true;
      // Vue3 compatability for Vue.set
      item = { ...item };
    },

    async create_company_period_for_company_clicked(company_id) {
      this.$emit('create_company_period_for_company', company_id);
    },

    async calculate_settled(company_period_id) {
      try {
        const res = await axios.post(`/samfund/calculate/settled/${company_period_id}`);

        if (res.status !== 201) {

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_CALCULATE_SETTLED'));
          return;
        }

        this.$emit('update_company_period', res.data);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.FAITH.SETTLED_CALCULATED'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_CALCULATE_SETTLED'));
      }
    },

    async calculate_advance(company_period_id) {
      try {
        const res = await axios.post(`/samfund/calculate/advance/${company_period_id}`);

        if (res.status !== 201) {

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_CALCULATE_ADVANCE'));
          return;
        }

        this.$emit('update_company_period', res.data);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMPANY_PERIOD.ADVANCE_CALCULATED'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.UNABLE_CALCULATE_ADVANCE'));
      }
    },

    handle_row_clicked(row, index, e) {
      if (row._showDetails) {
        row._showDetails = false;
        // Vue3 compatability for Vue.set
        row = { ...row };
      }
      else {
        row._showDetails = true;
        // Vue3 compatability for Vue.set
        row = { ...row };
      }

    },

    add_new_row(item) {
      item.newRow = this.newRowValue;
      // Vue3 compatability for Vue.set
      item = { ...item };

      this.newRowValue = "";
    },

    async download_company_period_report_clicked(id) {
      // redovisningsrakning file
      try {

        const lang = this.lang;

        const res = await axios.post(`/samfund/redovisningsrakning/${id}`, { lang });

        if (res.status !== 201) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.REDOVISNINGSRAKNING.UNABLE_CREATE'));
          return;
        }

        downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
      }
      catch (err) {
        console.error('download_company_period_report_clicked error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FAITH.REDOVISNINGSRAKNING.UNABLE_CREATE'));
      }
    },

    replace_null_empty(value) {
      if (value === null || value === undefined) {
        return '';
      }

      return value;
    },

    create_company_period_clicked() {
      this.$emit('create_company_period', company_period_id);
    },

    select_company_clicked(company_id) {
      this.$emit('select_company', company_id);
    },

    select_company_period_clicked(company_period_id) {
      this.$emit('select_company_period', company_period_id);
    },

    delete_company_period_clicked(company_period_id) {
      this.$emit('delete_company_period', company_period_id);
    },


    table_row_class(item, type) {
      if (item && type === 'row') {
        if (item.head) {
          return null;
        }
      }

      if (item.is_missing !== null && item.is_missing) {
        return 'table-danger';
      }

      return null;
    }


  },
  data() {
    return {
      lang: 'sv',
      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [
        {
          key: 'company.cnum',
          label: this.$t('COMPANY_PERIOD.CNUM'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'company.name',
          label: this.$t('COMPANY_PERIOD.COMPANY_NAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'advance_member_count',
          label: this.$t('COMPANY_PERIOD.MEMBER_COUNT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'advance_income_amount',
          label: this.$t('COMPANY_PERIOD.ADVANCE_INCOME_AMOUNT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'advance_payout_amount',
          label: this.$t('COMPANY_PERIOD.ADVANCE_PAYOUT_AMOUNT'),
          formatter: (_, __, item) => {
            return item.advance_payout_amount;
          },
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-12 align-middle',
        },
        {
          key: 'expand',
          label: "",
          sortable: false
        },
      ],

    };
  }
};
</script>
