<template>
  <div>
    <div class="d-flex justify-content-end mb-4">

      <div class="card-toolbar">

        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="run_skv_job"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('COMPANY_PERIOD.CREATE_NEW_REGISTER_FILE') }} </a
        >
      </div>
    </div>

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            id="per-page"
            class="mr-sm-2 mx-0"
            style="max-width: 100px;"
            v-model="per_page"
            :options="per_page_options"
          ></b-form-select>

          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="member-registrations-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>


    <b-table
      id="member-registrations-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="memberRegistrationsTable"
      @filtered="onFiltered"
      @sort-changed="onSortChanged"
      :tbody-tr-class="rowClass"
    >
      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>

        </div>
      </template>

    </b-table>


    <div v-if="total_rows === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="scss" scoped>

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 10%;
}


:deep div {
  overflow: hidden;
  text-overflow: ellipsis;
}


.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

.v-select .v-input__control .v-input__slot .v-select__slot .v-select__selections {
  padding: 0 !important;
  min-height: 0 !important;
}

.v-select-list .v-list-item {
  min-height: 0 !important;
}

.v-select-list .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}


</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  name: "RegistrationTable",
  mixins: [ toasts ],
  props: ["show_create"],
  emits: ['member_clicked', 'skv_job_finished'],
  components: {

  },
  watch: {
    currentPeriodId() {
      this.$refs['memberRegistrationsTable'].refresh();
    }
  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.allSelected = false;
    },


    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

    get_options() {

      const options = {};

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      options.statuses = [ 'MEMBER' ];
      options.skv_period_id = this.currentPeriodId;
      options.skv_statuses = [ 'PEN_REGISTER', 'REGISTERED' ];

      return options;
    },


    refresh() {
      this.$refs['memberTable'].refresh();
    },

    // items are fetched here whenever pagination ahas changed or
    // by forcing reload via: this.$refs['memberTable'].refresh();
    ajax_pagination: function(ctx, callback) {


      const vm = this;
      let params = {
        pageNo: ctx.current_page,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      this.get_page_count();

      const options = this.get_options();

      axios
        .post(`/skv/members/skv_status/pagination`, {
          statuses: ['MEMBER'],
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        })
        .then(res => {
          var arr = res.data;

          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
          console.error('pagination get error', err);
        });

      return null;
    },


    async get_page_count() {

      try {
        const options = this.get_options();


        const res = await axios.post(`/skv/members/skv_status/count`, { options: options, statuses: ['MEMBER'], });

        if (res.status === 200) {
          this.total_rows = res.data.count;
        }
      }
      catch (err) {
        console.error('get_page_count error ', err);
      }

    },


    async run_skv_job() {
      try {

        const loader = this.$loading.show();

        const res = await axios.post(`/skv/export/member/registration`, {
          skv_period_id: this.currentPeriodId
        });

        loader && loader.hide();

        if (res.status !== 201) {

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));

          return;
        }

        downloadWithAxios(get_base_url() + `/dlfile/${res.data.file.linkstr}`, res.data.file.name);

        this.$emit('skv_job_finished', res.data);
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));
      }

    },

    select_member_clicked(member_id) {
      console.log('select_member_clicked', member_id)
      this.$emit('member_clicked', member_id);
    },


  },
  data() {
    return {
      total_rows: 0,
      orderBy: { created_at: 'desc' },
      allSelected: false,


      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 50,
      current_page: 1,
      per_page_options: [{ value: 50, text: '50' }, { value: 250, text: '250' }, { value: 500, text: '500' }],
      fields: [

        {
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },

        {
          key: 'lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },

        {
          key: 'fmt_personnr',
          label: this.$t('MEMBER.PERSONNR'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'rdt',
          label: this.$t('MEMBER.RDT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },
        {
          key: 'skv_period_name',
          label: this.$t('SKV.SKV_PERIOD'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-12 align-middle',
        },

      ],

    };
  }
};
</script>
